import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { Blocks, ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { BasicHero } from "@shared";
import { PageWidth } from "@util/standard";

interface Props extends PageProps {
  data: Query;
}

export default function PrivacyPolicyPage({ data: { sanityPoliciesPage, sanityHeader } }: Props) {
  if (sanityPoliciesPage == null)
    return <ErrorMsg data={sanityPoliciesPage} msg="Error fetching data for page" />;

  const { _type, pageColour, title, seo, blockContent, ctas, blocks } = sanityPoliciesPage;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug="faqs" />
      <Header data={sanityHeader} />
      <BasicHero heading={title} blockContent={blockContent} ctas={ctas} />
      <PageWidth>{blocks && <Blocks data={blocks} />}</PageWidth>
    </div>
  );
}

export const query = graphql`
  query policyPageQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityPoliciesPage {
      seo {
        ...sanitySeo
      }
      title
      blockContent {
        _rawBlockContent
      }
      ctas {
        ...sanityLink
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
    }
  }
`;
